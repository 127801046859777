import { useCallback } from "react";
import { useAws } from "./useAws";
import {
  ListObjectsV2Command,
  S3Client,
  GetObjectCommand,
} from "@aws-sdk/client-s3";

export const useS3 = () => {
  const aws = useAws();

  const getS3 = useCallback(async () => {
    const credentials = await aws.getCredentials();

    if (!credentials || !aws.s3Prefix) {
      return null;
    }

    const s3 = new S3Client({
      region: process.env.REACT_APP_S3_LESSON_TRANSCRIPTION_BUCKET_REGION,
      credentials,
    });

    return s3;
  }, [aws]);

  const listObjects = useCallback(async () => {
    const s3 = await getS3();

    if (!s3) {
      return null;
    }

    const objects = await s3
      .send(
        new ListObjectsV2Command({
          Bucket: process.env.REACT_APP_LESSON_TRANSCRIPTIONS_S3_BUCKET,
          Prefix: aws.s3Prefix,
        })
      )
      .then((res) => res.Contents?.filter((item) => item.Size));

    return objects;
  }, [aws, getS3]);

  const getOjbect = useCallback(
    async (key: string) => {
      const s3 = await getS3();

      if (!s3) {
        return null;
      }

      const object = await s3.send(
        new GetObjectCommand({
          Bucket: process.env.REACT_APP_LESSON_TRANSCRIPTIONS_S3_BUCKET,
          Key: key,
        })
      );

      return object;
    },
    [getS3]
  );

  return {
    listObjects,
    getOjbect,
  };
};
