import { Routes, Route } from "react-router-dom";
import { Articles } from "./pages/Main";
import { Article } from "./pages/Article";
import { Header } from "./layout/header/Header";
import { Body } from "./layout/Body";
import { Lessons } from "./pages/Lessons";
import { Lesson } from "./pages/Lesson";

function App() {
  return (
    <div className="h-[100vh] bg-amber-50 flex flex-col overflow-hidden">
      <Header />
      <Body>
        <Routes>
          <Route path="/:articleId" element={<Article />} />
          <Route path="*" element={<Articles />} />
          <Route path="/lessons" element={<Lessons />} />
          <Route path="/lessons/:lessonId" element={<Lesson />} />
        </Routes>
      </Body>
    </div>
  );
}

export default App;
