import { FC, useState } from "react";
import Button from "@mui/material/Button";
import { HiOutlineMenuAlt4 } from "react-icons/hi";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";

export const NavigationMenu: FC<{
  options: { label: string; route: string }[];
}> = ({ options }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button onClick={handleClick}>
        <HiOutlineMenuAlt4 size={24} />
      </Button>
      <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose}>
        {options.map((option) => (
          <Link to={option.route}>
            <MenuItem onClick={handleClose}>{option.label}</MenuItem>
          </Link>
        ))}
      </Menu>
    </div>
  );
};
