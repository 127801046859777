export const reflexiveVerbs = {
  id: "reflexive-verbs",
  title: "Reflexive verbs",
  readTime: 3,
  illustration: "reflexive-verbs.jpg",
  summary:
    "Struggling to understand reflexive verbs in Spanish? Here’s an easy explanation that you might like if you're a learner—or maybe dislike if you're a teacher!",
  content: (
    <article>
      <p className="font-semibold">
        Struggling to understand reflexive verbs in Spanish? Here’s an easy
        explanation that you might like if you're a learner—or maybe dislike if
        you're a teacher!
      </p>
      <p>
        You can recognize a reflexive verb if it ends with “<span>-se</span>”:{" "}
        <em>despertarse</em>, <em>ducharse</em>, <em>romperse</em>. The "
        <span>se</span>" is the reflexive part that shows the action is directed
        toward the person performing it. Think of it like throwing a stick: if
        you throw it at someone else, they’re the one who gets hit. But imagine
        some sticks are curved like boomerangs—they come back and hit you.
        Actions that "hit" the person performing them are called reflexive. For
        example, you wake yourself up (<em>despertarse</em>).
      </p>

      <p>
        You can also wake up someone else, in which case you're not the one
        "affected" by your own action. When that happens, you don’t need the
        reflexive particle "<span>-se</span>"; you simply use <em>despertar</em>
        . So, use <em>despertar</em> to wake someone else up, and use{" "}
        <em>despertarse</em> if you're waking up yourself.
      </p>

      <p>
        Turns out, we wake ourselves up far more often than we wake others up,
        which is why the reflexive form <em>despertarse</em> is so common! So
        remember, use <em>despertar</em> to wake someone else and{" "}
        <em>despertarse</em> to wake yourself.
      </p>
    </article>
  ),
};
