export const simpleAndImperfectPast = {
  id: "simple-and-imperfect-past",
  title: "Simple and imperfect past",
  summary:
    "What do you know about the two simplest geometrical shapes: the dot and the line? A dot has no length, existing as a single point in space, while a line extends with length, formed by connecting many dots together. This concept can beautifully illustrate the difference between two past tenses in Spanish: the Simple Past (pretérito) and the Imperfect Past (imperfecto)",
  readTime: 8,
  illustration: "dots.png",
  content: (
    <article>
      <p className="font-semibold">
        The Dot and the Line: Understanding Simple Past and Imperfect Past in
        Spanish
      </p>

      <p>
        What do you know about the two simplest geometrical shapes: the dot and
        the line? A dot has no length, existing as a single point in space,
        while a line extends with length, formed by connecting many dots
        together. This concept can beautifully illustrate the difference between
        two past tenses in Spanish: the Simple Past (pretérito) and the
        Imperfect Past (imperfecto).
      </p>

      <p>Simple Past as a Dot</p>

      <p>
        In the Simple Past, actions are like dots—single, completed events that
        occur at a specific point in time. For example, consider the verb to
        know (saber):
      </p>

      <p>The student knew the answer when the professor asked him.</p>

      <p>
        Here, "knew" represents an instantaneous action. The knowledge happened
        in a flash, corresponding to a specific moment (a single dot on the
        timeline).
      </p>

      <p>Imperfect Past as a Line</p>

      <p>
        In the Imperfect Past, actions are more like lines—formed of many dots
        that stretch across a period of time. Using the same verb, to know:
      </p>

      <p>The student knew he had to study hard to pass the exams.</p>

      <p>
        Here, "knew" reflects continuous or habitual knowledge—a state of mind
        that persisted over time. The action doesn’t happen in a single moment;
        instead, it’s made up of many smaller moments of realization (dots) that
        together form a line.
      </p>

      <p>Converting Between the Dot and the Line</p>

      <p>
        Interestingly, you can often transform one tense into the other by
        shifting perspective. Take the "line" of the Imperfect and break it down
        into its individual "dots" of Simple Past:
      </p>

      <p>
        During his studies, the student repeatedly asked himself why it was
        necessary to work hard. Each time, he knew the answer.
      </p>

      <p>
        Each moment of realization (dot) contributes to the broader, continuous
        understanding represented by the line.
      </p>

      <p>
        Conversely, the reverse operation is less intuitive but still possible.
        Imagine combining all those individual moments into one uninterrupted
        sequence, forming the Imperfect:
      </p>

      <p>He knew he had to study hard throughout his education.</p>

      <p>The Key Difference: Instantaneous vs. Continuous</p>

      <p>
        The Simple Past resists division. A punch, for instance, cannot be
        broken into smaller punches—it’s a single, indivisible action.
        Similarly, an instantaneous event in the Simple Past cannot be stretched
        into a longer action.
      </p>

      <p>
        The Imperfect Past, however, is inherently divisible. A long action,
        like "knowing something over time," can be split into smaller, discrete
        actions. For example:
      </p>

      <p>
        A boxer delivering a series of punches creates a line of actions over
        time.
      </p>

      <p>
        Each punch in the series, however, is a distinct dot—a completed action
        that belongs to the Simple Past.
      </p>

      <p>A Practical Exercise</p>

      <p>
        When deciding between Simple Past and Imperfect Past, imagine converting
        one to the other:
      </p>

      <p>
        If you can’t divide the action further, it’s likely Simple Past (a dot).
      </p>

      <p>
        If you can break it down into smaller, repeated actions, it’s likely
        Imperfect (a line).
      </p>

      <p>
        In short, the Simple Past captures the moment; the Imperfect Past
        captures the story. Together, they bring depth and nuance to how we
        express time and actions in language. By seeing them as dots and lines,
        you can make sense of these tenses—and perhaps, understand them in a way
        that’s as timeless as geometry itself.
      </p>
    </article>
  ),
};
