import { FC } from "react";
import { articles } from "../articles";
import { useParams } from "react-router-dom";

export const Article: FC = () => {
  const { articleId } = useParams();

  const article = articles.find((article) => article.id === articleId);

  return (
    <div className="flex flex-col gap-6">
      <h1>{article?.content}</h1>
    </div>
  );
};
