import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import { useAuth } from "react-oidc-context";
import { useCallback, useMemo } from "react";

export const useAws = () => {
  const auth = useAuth();

  const getCredentials = useCallback(async () => {
    if (!auth.user?.id_token) {
      return null;
    }

    const identityClient = new CognitoIdentityClient({
      region: process.env.REACT_APP_S3_LESSON_TRANSCRIPTION_BUCKET_REGION,
    });

    const credentials = await fromCognitoIdentityPool({
      client: identityClient,
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL!,
      logins: {
        [process.env.REACT_APP_IDENTITY_PROVIDER!]: auth.user.id_token,
      },
    })();

    return credentials;
  }, [auth.user?.id_token]);

  const s3Prefix = useMemo(() => auth.user?.profile.sub, [auth.user]);

  const aws = useMemo(() => {
    return {
      getCredentials,
      s3Prefix,
    };
  }, [getCredentials, s3Prefix]);

  return aws;
};
