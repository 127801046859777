import { FC, useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Avatar } from "@mui/material";

export const UserMenu: FC<{
  options: { label: string; action: () => void }[];
  name: string;
}> = ({ options, name }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button onClick={handleClick}>
        <Avatar variant="rounded">{name.charAt(0).toUpperCase()}</Avatar>
      </Button>
      <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose}>
        {options.map((option) => (
          <button onClick={option.action}>
            <MenuItem onClick={handleClose}>{option.label}</MenuItem>
          </button>
        ))}
      </Menu>
    </div>
  );
};
