import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useS3 } from "../hooks/useS3";

export const Lesson: FC = () => {
  const { lessonId } = useParams();

  const { getOjbect } = useS3();

  const [html, setHtml] = useState("");

  useEffect(() => {
    if (!lessonId) {
      return;
    }

    getOjbect(lessonId)
      .then((obj) => obj?.Body?.transformToString())
      .then((str) => {
        if (str) {
          setHtml(str);
        }
      });
  }, [getOjbect, lessonId]);

  return <div dangerouslySetInnerHTML={{ __html: html }} />;
};
