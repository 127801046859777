import { FC } from "react";
import { Link } from "react-router-dom";

export const ArticlePreview: FC<{
  id: string;
  title: string;
  summary: string;
  readTime: number;
  illustration: string;
}> = ({ id, title, summary, readTime, illustration }) => {
  return (
    <li className=" flex flex-col gap-3" key={id}>
      <Link to={`/${id}`} className="flex flex-col gap-5">
        <img
          className="rounded-xl max-w-[400px] max-h-[400px]"
          src={illustration}
          alt="article-preview-image"
        />
        <div className="flex flex-col gap-5">
          <div className="flex flex-col items-start justify-between gap-1">
            <span className="text-xl">{title}</span>
            <span className="text-xs text-gray-400">
              {readTime} minute read
            </span>
          </div>
          <p className="text-gray-400 max-w-[800px]">{summary}</p>
        </div>
      </Link>
    </li>
  );
};
