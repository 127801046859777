import { FC } from "react";
import { useAuth } from "react-oidc-context";
import { NavigationMenu } from "./components/NavigationMenu";
import { Button } from "@mui/material";
import { UserMenu } from "./components/UserMenu";

export const Header: FC = () => {
  const auth = useAuth();

  const navigationOptions = [
    {
      route: "/",
      label: "Articles",
    },
  ];
  const userOptions = [
    {
      label: "Sign out",
      action: auth.signoutSilent,
    },
  ];

  if (auth.user) {
    navigationOptions.push({ label: "My lessons", route: "/lessons" });
  }

  return (
    <div className="flex justify-between items-center px-3 bg-amber-300 text-lg border-b h-[60px]">
      <NavigationMenu options={navigationOptions} />

      {auth.isAuthenticated ? (
        <UserMenu name={auth.user?.profile.email!} options={userOptions} />
      ) : (
        <Button onClick={() => auth.signinRedirect()}>Sign in</Button>
      )}
    </div>
  );
};
