import { FC } from "react";
import { ArticlePreview } from "../components/ArticlePreview";
import { articles } from "../articles";

export const Articles: FC = () => {
  return (
    <div className="flex flex-col gap-10">
      <ul className="flex flex-col gap-20">
        {articles.map((article) => (
          <ArticlePreview
            id={article.id}
            title={article.title}
            summary={article.summary}
            readTime={article.readTime}
            illustration={article.illustration}
          />
        ))}
      </ul>
    </div>
  );
};
