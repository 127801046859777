import { FC, useEffect, useState } from "react";
import { useS3 } from "../hooks/useS3";
import { _Object } from "@aws-sdk/client-s3";
import { Link } from "react-router-dom";

export const Lessons: FC = () => {
  const { listObjects } = useS3();

  const [lessons, setLessons] = useState<_Object[]>([]);

  useEffect(() => {
    listObjects().then((objects) => {
      if (objects) {
        setLessons(objects);
      }
    });
  }, [listObjects]);

  return (
    <ul>
      {lessons.map((lesson) => (
        <li key={lesson.Key}>
          <Link
            to={`/lessons/${encodeURIComponent(lesson.Key!)}`}
            className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
          >
            Lesson from{" "}
            {new Date(
              +lesson.Key?.split("/")[1].split(".")[0]!
            ).toLocaleDateString()}
          </Link>
        </li>
      ))}
    </ul>
  );
};
